.ProductDetailInfo-container {
    width: 100%;
}

.ProductDetailInfo-formGroup {
    margin-bottom: 24px;
}

.ProductDetailInfo-formGroup:last-child {
    margin-bottom: 0;
}

.ProductDetailInfo-formGroup label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}

.ProductDetailInfo-genderGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

.ProductDetailInfo-genderOption {
    position: relative;
    cursor: pointer;
}

.ProductDetailInfo-genderOption input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.ProductDetailInfo-genderLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 14px;
    background-color: white;
    transition: all 0.2s ease;
}

.ProductDetailInfo-genderOption input[type="radio"]:checked + .ProductDetailInfo-genderLabel {
    border-color: #007aff;
    background-color: #f0f7ff;
    color: #007aff;
}

.ProductDetailInfo-commonGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.ProductDetailInfo-commonOption {
    position: relative;
    cursor: pointer;
}

.ProductDetailInfo-commonOption input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.ProductDetailInfo-commonLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    border-radius: 999px;
    background-color: #F2F2F2;
    font-size: 14px;
    transition: all 0.2s ease;
    text-align: center;
    white-space: nowrap;
}

.ProductDetailInfo-commonOption input[type="radio"]:checked + .ProductDetailInfo-commonLabel {
    background-color: #000;
    color: white;
}

.ProductDetailInfo-commonOption input[type="radio"]:disabled {
    cursor: not-allowed;
}

.ProductDetailInfo-commonOption input[type="radio"]:disabled + .ProductDetailInfo-commonLabel,
.ProductDetailInfo-commonLabel--disabled {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
    border: 1px solid #e0e0e0;
}

.ProductDetailInfo-conditionGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 12px;
}

.ProductDetailInfo-sizeGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 10px;
    margin-top: 10px;
}

.ProductDetailInfo-sizeOption {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ProductDetailInfo-sizeOption input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.ProductDetailInfo-sizeLabel {
    /* Inherits from commonLabel, add specific styles if needed */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    transition: all 0.2s ease;
}

.ProductDetailInfo-sizeOption input[type="radio"]:checked + .ProductDetailInfo-sizeLabel {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.ProductDetailInfo-sizeOption:hover .ProductDetailInfo-sizeLabel {
    border-color: #007bff;
}

.ProductDetailInfo-sizeOption input[type="radio"]:disabled {
    cursor: not-allowed;
}

.ProductDetailInfo-sizeOption input[type="radio"]:disabled + .ProductDetailInfo-sizeLabel,
.ProductDetailInfo-sizeLabel--disabled {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
    border-color: #e0e0e0;
}

.ProductDetailInfo-loadingIndicator,
.ProductDetailInfo-error,
.ProductDetailInfo-placeholderMessage,
.ProductDetailInfo-noResults {
    padding: 12px;
    text-align: center;
    font-size: 14px;
    color: #666;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-top: 8px;
}

.ProductDetailInfo-loadingSpinner {
    display: inline-block;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #007aff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: ProductDetailInfo-spin 1s linear infinite;
    margin-right: 8px;
    vertical-align: middle;
}

@keyframes ProductDetailInfo-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
