.UploadFooter-container {
    border-top: 1px solid #0000001A;
    width: 100%;
    height: 80px;
    padding: 0 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.UploadFooter-negotiation {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap : 12px;
}

.UploadFooter-negotiation .text-container {
    display: flex;
    flex-direction: column;
}


.UploadFooter-negotiation .title {
    font-size: 16px;
    line-height: 22px;
    color: #000;
}

.UploadFooter-negotiation .description {
    font-size: 14px;
    font-weight: 350;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #787878;
}

.UploadFooter-negotiation .checkbox-container {
    display: flex;
    align-items: center;
    gap: 8px;
}


.UploadFooter-estimated {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.UploadFooter-estimated .label {
    font-size: 14px;
    font-weight: 350;
    color: #787878;
    line-height: 18px;
    letter-spacing: -0.4px;
}

.UploadFooter-estimated .amount {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    line-height: 26px;
    letter-spacing: -0.4px;
}   

.UploadFooter-estimated .currency {
    font-size: 14px;
    margin-left: 2px;
    line-height: 26px;
    letter-spacing: -0.4px;
}

.UploadFooter-submit-button:hover {
    background-color: #333;
}
