.ProductBasicInfo-container {
    width: 100%;
    padding: 16px 0;
}

.ProductBasicInfo-inputContainer {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.ProductBasicInfo-inputHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;
}

.ProductBasicInfo-inputHeader label {
    font-size: 16px;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: -0.019rem;
}

.ProductBasicInfo-charCount {
    color: #666;
    font-size: 0.75rem;
    font-weight: 350;
    line-height: 1rem;
    letter-spacing: -0.025rem;
}

.ProductBasicInfo-charCount--exceeded {
    color: #ff0000;
    font-weight: 500;
}

.ProductBasicInfo-formInput,
.ProductBasicInfo-formTextarea {
    width: 100%;
    padding: 0 0.813rem;
    border: 0.0625rem solid #D9D9D9;
    border-radius: 0.25rem !important;
    font-size: 0.875rem;
    font-weight: 350;
    line-height: 1.25rem;
    letter-spacing: -0.025rem;
    font-family: inherit;
    outline: none;
}

.ProductBasicInfo-formInput {
    height: 2.75rem;
}

.ProductBasicInfo-formInput:focus,
.ProductBasicInfo-formTextarea:focus {
    border-color: #007aff;
}

.ProductBasicInfo-formInput--error,
.ProductBasicInfo-formTextarea--error {
    border-color: #ff0000;
}

.ProductBasicInfo-formTextarea {
    padding: 0.813rem;
    resize: none;
}

.ProductBasicInfo-errorMessage {
    color: #ff0000;
    font-size: 0.75rem;
    margin-top: 0.25rem;
}

.ProductBasicInfo-spacing {
    height: 1rem;
}

/* 브랜드 검색 결과 스타일 */
.ProductBasicInfo-brandSearchResults {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin-top: 4px;
}

.ProductBasicInfo-brandResultsList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.ProductBasicInfo-brandResultItem {
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.2s;
}

.ProductBasicInfo-brandResultItem:last-child {
    border-bottom: none;
}

.ProductBasicInfo-brandResultItem:hover {
    background-color: #f5f5f5;
}

.ProductBasicInfo-loadingIndicator {
    padding: 10px 15px;
    text-align: center;
    color: #666;
    font-size: 14px;
}

.ProductBasicInfo-noResults {
    padding: 10px 15px;
    text-align: center;
    color: #666;
    font-size: 14px;
}

.ProductBasicInfo-suggestionsContainer {
    position: absolute;
    width: 100%;
    background-color: white;
    border: 1px solid #D9D9D9;
    border-radius: 0.25rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin-top: 4px;
    padding: 12px;
}

.ProductBasicInfo-suggestionsInlineContainer {
    margin: 10px 0;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 10px;
    background-color: #f9f9f9;
}

.ProductBasicInfo-suggestionSection {
    margin-bottom: 10px;
}

.ProductBasicInfo-suggestionSection h4 {
    font-size: 14px;
    margin: 0 0 5px 0;
    color: #666;
}

.ProductBasicInfo-suggestionList {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.ProductBasicInfo-suggestionItem {
    display: inline-block;
    padding: 4px 12px;
    background-color: #f0f0f0;
    border-radius: 16px;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s;
}

.ProductBasicInfo-suggestionItem:hover {
    background-color: #e0e0e0;
}

.ProductBasicInfo-suggestionItem--selected {
    background-color: #4a90e2;
    color: white;
}

.ProductBasicInfo-suggestionLoading, 
.ProductBasicInfo-noSuggestions {
    font-size: 13px;
    color: #888;
    padding: 5px;
    text-align: center;
}

.ProductBasicInfo-selectedItemsContainer {
    margin: 10px 0;
}

.ProductBasicInfo-selectedItem {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.ProductBasicInfo-selectedLabel {
    font-size: 13px;
    color: #666;
    margin-right: 8px;
}

.ProductBasicInfo-selectedTag {
    display: inline-flex;
    align-items: center;
    background-color: #4a90e2;
    color: white;
    padding: 4px 10px;
    border-radius: 16px;
    font-size: 13px;
}

.ProductBasicInfo-removeTag {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    margin-left: 5px;
    cursor: pointer;
    padding: 0 5px;
}

.ProductBasicInfo-removeTag:hover {
    opacity: 0.8;
}

/* 선택된 브랜드 스타일 */
.ProductBasicInfo-selectedBrand {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
}

.ProductBasicInfo-brandTag {
    display: inline-flex;
    align-items: center;
    background-color: #f0f8ff;
    border: 1px solid #007aff;
    border-radius: 16px;
    padding: 4px 12px;
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 14px;
    color: #007aff;
}

.ProductBasicInfo-removeBrand {
    background: none;
    border: none;
    color: #007aff;
    margin-left: 6px;
    font-size: 16px;
    cursor: pointer;
    padding: 0 2px;
    line-height: 1;
}

.ProductBasicInfo-removeBrand:hover {
    color: #0056b3;
}
