.user-type-selector {
    width: 100%;
    padding: 16px 0;
}

.selector-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
}

.selector-header h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.selector-options {
    display: flex;
    gap: 12px;
    margin-bottom: 12px;
}

.option-card {
    flex: 1;
    position: relative;
    cursor: pointer;
}

.option-card input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.card-content {
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: white;
    transition: all 0.2s ease;
}

.option-card input[type="radio"]:checked + .card-content {
    border-color: #007aff;
    background-color: #f0f7ff;
}

.lightning-icon {
    font-size: 16px;
}

.option-text {
    font-size: 14px;
    color: #333;
}

.beta-notice {
    font-size: 12px;
    color: #666;
    margin: 0;
}
