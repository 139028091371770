/* General form styles reused */
.ProductPriceInfo-priceGroup, 
.ProductPriceInfo-shippingGroup {
    margin-bottom: 24px;
}

.ProductPriceInfo-shippingGroup { 
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.ProductPriceInfo-inputHeader {
    margin-bottom: 8px;
}

.ProductPriceInfo-inputHeader label {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.ProductPriceInfo-formInput {
    width: 100%;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 14px;
    box-sizing: border-box;
    transition: border-color 0.2s ease;
}

.ProductPriceInfo-formInput:focus {
    border-color: #007aff;
    outline: none;
}

/* Price specific */
.ProductPriceInfo-priceInputWrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.ProductPriceInfo-priceUnit {
    position: absolute;
    right: 12px;
    color: #666;
    line-height: 1;
    display: flex;
    align-items: center;
    height: 100%;
    pointer-events: none; /* Prevent unit from blocking input */
}

.ProductPriceInfo-shippingFeeRow {
    display: flex; 
    flex-direction: column;
    flex: 1;
}

.ProductPriceInfo-shippingFeeRow:last-child {
    margin-bottom: 0;
}

.ProductPriceInfo-shippingFeeLabel {
    font-size: 14px;
    color: #333;
    flex-shrink: 0;
    margin-bottom: 8px;
}

.ProductPriceInfo-shippingFeeInput {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
}

.ProductPriceInfo-shippingFeeInput .ProductPriceInfo-formInput {
    padding-right: 32px; /* 원 단위 표시 공간 확보 */
    height: 40px;
    box-sizing: border-box;
}

.ProductPriceInfo-shippingFeeInput .ProductPriceInfo-priceUnit {
    right: 12px;
    font-size: 14px;
}

/* Error message style */
.ProductPriceInfo-errorMessage {
    color: #d9534f; /* Bootstrap's danger color */
    font-size: 12px;
    margin-top: 5px;
}

/* Toggle styles - If they belong here, rename them */
.ProductPriceInfo-toggleContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 16px; /* Add margin if toggle is below shipping */
}

.ProductPriceInfo-toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.ProductPriceInfo-toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.ProductPriceInfo-toggleSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 24px;
}

.ProductPriceInfo-toggleSlider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .ProductPriceInfo-toggleSlider {
    background-color: #000000;
}

input:checked + .ProductPriceInfo-toggleSlider:before {
    transform: translateX(26px);
}

.ProductPriceInfo-toggleDescription {
    color: #666;
    font-size: 14px;
}
