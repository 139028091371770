/* ProductBrandInfo specific styles */
.ProductBrandInfo-container {
    position: relative; /* Needed for absolute positioning of search results */
}

.ProductBrandInfo-container label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}

.ProductBrandInfo-brandInputArea {
    /* Container for either the selected tag or the search input */
}

.ProductBrandInfo-searchContainer {
    /* Styles for the input wrapper if needed */
}

.ProductBrandInfo-searchInput {
    width: 100%;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 14px;
    box-sizing: border-box;
    transition: border-color 0.2s ease;
}

.ProductBrandInfo-searchInput:focus {
    border-color: #007aff;
    outline: none;
}

.ProductBrandInfo-selectedBrandTag {
    display: inline-flex;
    align-items: center;
    background-color: #e0f2fe;
    color: #0c508c;
    padding: 6px 12px;
    border-radius: 16px;
    font-size: 14px;
    border: 1px solid #b3d7ff;
}

.ProductBrandInfo-brandName {
    margin-right: 8px;
}

.ProductBrandInfo-removeBrandButton {
    background: none;
    border: none;
    color: #0c508c;
    cursor: pointer;
    font-size: 16px;
    padding: 0 4px;
    line-height: 1;
    margin-left: 4px; /* Add some space */
}

.ProductBrandInfo-removeBrandButton:hover {
    color: #0056b3; /* Darken on hover */
}

.ProductBrandInfo-brandSearchResults {
    position: absolute;
    top: calc(100% + 4px); /* Position below the label + input area */
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    z-index: 10; /* Ensure it appears above other elements */
    padding: 4px 0; /* Add some padding */
}

.ProductBrandInfo-brandResultItem {
    padding: 10px 16px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s ease;
}

.ProductBrandInfo-brandResultItem:hover,
.ProductBrandInfo-brandResultItem--selected {
    background-color: #f0f7ff; /* Light blue on hover/selection */
    color: #007aff;
}

/* Loading and Message Styles */
.ProductBrandInfo-loadingIndicator,
.ProductBrandInfo-noResults {
    padding: 12px;
    text-align: center;
    font-size: 14px;
    color: #666;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-top: 8px;
    display: flex; /* Align spinner and text */
    align-items: center;
    justify-content: center;
}

.ProductBrandInfo-loadingSpinner {
    display: inline-block;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #007aff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: ProductBrandInfo-spin 1s linear infinite;
    margin-right: 8px;
}

@keyframes ProductBrandInfo-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
