.upload-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    position: relative;
    height: 93vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.upload-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .input-container {
        width: 100%;
    }

    .input-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
    }

    .input-header label {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.2px;
    }

    .char-count {
        color: #787878;
        font-size: 13px;
        font-weight: 350;
        line-height: 18px;
        letter-spacing: -0.2px;
    }

    .form-input,
    .form-textarea {
        width: 100%;
        display: flex;
        padding: 12px 16px;
        align-items: center;
        align-self: stretch;
        border: 1px solid #ddd;
        border-radius: 10px;
        font-size: 16px;
        box-shadow: 0 0 0 2px #E6E6E6;
    }

    .form-input:focus,
    .form-textarea:focus {
        outline: none;
        border-color: #666;
    }

    .form-textarea {
        resize: vertical;
        min-height: 120px;
    }

    .error-message {
        color: #e53e3e;
        font-size: 14px;
        margin-top: 4px;
    }
}

/* 수평 레이아웃을 위한 스타일 추가 */
.upload-content-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex: 1;
    overflow: hidden;
}

.upload-left-column {
    flex: 0 0 345px; /* 왼쪽 컬럼 너비 30% 고정 */
    align-self: flex-start;
    height: fit-content;
    overflow: hidden;
}

.upload-right-column {
    flex: 1;
    overflow-y: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-height: calc(95vh - 160px); /* 헤더(80px)와 푸터(80px) 높이를 뺀 높이 */
}

.form-group {
    width: 100%;
    margin-bottom: 24px;
}


.submit-button {
    width: 100%;
    padding: 16px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 24px;
}

.submit-button:hover {
    background-color: #333;
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.upload-header-wrapper {
    position: static;
    top: 0;
    z-index: 100;
    background-color: white;
    width: 100%;
}

.upload-footer-wrapper {
    position: static;
    bottom: 0;
    z-index: 100;
    height: 80px;
    width: 100%;
}

/* 모바일 반응형 레이아웃 */
@media (max-width: 768px) {
    .upload-content-container {
        flex-direction: column;
    }
    
    .upload-left-column,
    .upload-right-column {
        flex: 1 1 100%;
        width: 100%;
    }
    
    .upload-left-column {
        position: relative;
        top: 0;
    }
}

/* 모범셀러 에러 메시지 스타일 */
.certified-seller-error {
    max-width: 600px;
    margin: 100px auto;
    padding: 30px;
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.certified-seller-error h2 {
    font-size: 24px;
    color: #e74c3c;
    margin-bottom: 15px;
}

.certified-seller-error p {
    font-size: 16px;
    color: #333;
    margin-bottom: 25px;
}

.certified-seller-error button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.certified-seller-error button:hover {
    background-color: #2980b9;
}

.common-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  
    .common-option {
        position: relative;
        cursor: pointer;
    }

    .common-option input[type="radio"] {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .common-label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 8px 14px;
        border-radius: 999px;
        background-color: #F2F2F2;
        transition: all 0.2s ease;
        text-align: center;
    }
}