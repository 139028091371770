.ImageUpload-container {
    width: 100%;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    margin-bottom: 20px;
}

.ImageUpload-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #000;
}

.ImageUpload-dropzone {
    position: relative;
    border: 2px dashed #ccc;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 15px;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
    width: 315px;
    height: 315px;
}

.ImageUpload-dropzone:hover {
    background-color: #f1f1f1;
}

.ImageUpload-dropzone--dragging {
    background-color: #e0f2fe; /* 연한 하늘색 배경 */
    border-color: #a0d2fa; /* 조금 더 진한 하늘색 테두리 */
}

.ImageUpload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 315px;
    height: 315px;
}

.ImageUpload-plusIcon {
    font-size: 36px;
    color: #aaa;
    margin-bottom: 10px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #000;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ImageUpload-text {
    color: #666;
    font-size: 14px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 350;
    line-height: 18px;
    letter-spacing: -0.2px;
}

.ImageUpload-fileInput {
    display: none;
}

.ImageUpload-dragOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: '#e0f2fe';
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    border-radius: 8px;
}

.ImageUpload-countText {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 12px;
    color: #888;
    margin-bottom: 30px;
    text-align: center;
    margin-top: 10px;
}

.ImageUpload-previewContainer {
    margin-top: 20px;
}

.ImageUpload-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.ImageUpload-previewItem {
    position: relative;
    width: 68px;
    height: 68px;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    background-color: #f0f0f0;
}

.ImageUpload-previewWrapper {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.ImageUpload-previewImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ImageUpload-deleteButton {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    transition: all 0.2s ease;
    opacity: 0;
}

.ImageUpload-previewWrapper:hover .ImageUpload-deleteButton {
    opacity: 1;
}

.ImageUpload-placeholder {
    width: 68px;
    height: 68px;
    border: 1px dashed #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ImageUpload-placeholderContent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.ImageUpload-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ImageUpload-modalContent {
    position: relative;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ImageUpload-modalImage {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
}

.ImageUpload-modalClose {
    position: absolute;
    top: 10px;
    right: 25px;
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.ImageUpload-modalClose:hover,
.ImageUpload-modalClose:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .ImageUpload-grid {
        justify-content: center;
    }
}
