.SuggestionList-container {
    margin: 0;
}

.SuggestionList-title {
    display: flex;
    padding: 11px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4px;
    color: white;
    border-radius: 40px;
    background: linear-gradient(78deg, #18A0FB 0%, #9747FF 35%, #FF4333 70%, #FFC107 100%);
    flex-wrap: wrap;
    width: fit-content;
}

.SuggestionList-section {
    margin-bottom: 15px;

    .label {
        padding-top: 16px;
        padding-bottom: 8px;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.2px;
    }
}

.SuggestionList-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.suggestion-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 16px;
    padding: 6px 12px;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.suggestion-item:hover {
    background-color: #f0f0f0;
    border-color: #ccc;
}

.suggestion-item.selected {
    background-color: #e6f7ff;
    border-color: #91d5ff;
    color: #1890ff;
}

.common-option input[type="radio"]:checked + .common-label {
    background-color: #000;
    color: white;
}

.suggestion-loading, .no-suggestions {
    font-size: 13px;
    color: #888;
    padding: 5px;
    text-align: center;
}

/* 브랜드 검색 결과 스타일 */
.brand-results-container {
    margin-top: 10px;
    border: 1px solid #eee;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.brand-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 12px;
    padding: 15px;
}

.brand-option {
    display: inline-block;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 5px;
}

.brand-option input[type="radio"] {
    margin: 0;
}

.brand-name {
    font-size: 14px;
}

.brand-name.selected {
    color: #1890ff;
    font-weight: 500;
}

.loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    margin: 8px 0;
}

.loading-spinner {
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top-color: #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.placeholder-message {
    padding: 15px;
    text-align: center;
    color: #888;
    font-size: 14px;
}
