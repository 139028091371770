.seller-certification-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.1);
}

.seller-certification-popup {
  background-color: white;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  animation: popup-appear 0.3s ease-out;
  position: relative;
  z-index: 1001;
}

@keyframes popup-appear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.popup-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.popup-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #999;
}

.close-button:hover {
  color: #333;
}

.popup-content {
  padding: 20px;
}

.popup-content h4 {
  margin: 0 0 15px 0;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.popup-content p {
  margin: 0 0 20px 0;
  font-size: 14px;
  line-height: 1.5;
  color: #666;
}

.certification-requirements {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  margin-top: 15px;
}

.requirement {
  display: flex;
  margin-bottom: 12px;
}

.requirement:last-child {
  margin-bottom: 0;
}

.requirement-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  margin-right: 12px;
  flex-shrink: 0;
}

.requirement-text {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.popup-footer {
  padding: 15px 20px;
  border-top: 1px solid #eee;
  text-align: right;
}

.confirm-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.confirm-button:hover {
  background-color: #0069d9;
}
