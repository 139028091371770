.UploadHeader-container {
    padding: 15px;
    border-bottom: 1px solid #0000001A;
    background-color: white;
    z-index: 1000;
    width: 100%;
    max-width: 1200px;
}

.UploadHeader-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.UploadHeader-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.UploadHeader-title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin: 0;
}

.UploadHeader-label {
    display: flex;
    padding: 3px 6px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 16px;
    border-radius: 44px;
    color: #787878;
    background-color: #F2F2F2;
}

.UploadHeader-buttons {
    display: flex;
    align-items: center;
}

.UploadHeader-cancel-button {
   font-size: 16px;
   line-height: 22px;
   letter-spacing: -0.4px;
    border: none;
    background-color: white;
   height: 50px;
    margin-right: 16px;
}

.UploadHeader-upload-button {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4px;
    padding: 13px 16px;
    border: none;
    border-radius: 999px;
    color: white;
    background-color: black;
    height: 50px;
}